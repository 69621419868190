import { message } from "antd";
import { useEffect, useState } from "react";
import { AxiosResponse } from "axios";

import { api } from "../api";
import { analyticsColors, genderMap, workModeMap } from "../constants";
import { getApiErrorMsg } from "../utils/object-util";
import { isNotEmptyArray } from "../utils/type-util";

const pieChartPreData = {
  tooltip: {
    trigger: "item",
  },
  grid: { top: 8, right: 8, bottom: 24, left: 36 },
  series: [
    {
      type: "pie",
      avoidLabelOverlap: false,
      itemStyle: {
        borderColor: "#fff",
        borderWidth: 2,
        borderRadius: 5,
      },
      labelLine: {
        show: false,
      },
      label: {
        show: false,
      },
      emphasis: {
        label: {
          show: false,
        },
      },
    },
  ],
};

const stackedBarChartPreData = {
  grid: { top: 8, right: 8, bottom: 24, left: 36 },
  xAxis: {
    type: "category",
    data: [],
    axisLine: {
      show: false,
    },
    axisTick: {
      show: false,
    },
  },
  yAxis: {
    type: "value",
    axisPointer: {
      snap: true,
    },
  },
  tooltip: {
    trigger: "axis",
  },
  itemStyle: {
    borderColor: "#fff",
    borderWidth: 2,
  },
  series: [],
};

const useAnalytics = (
  path: string,
  key: "myTeam" | "gender" | "workMode" | "headCount" | "teamSplit"
) => {
  const [data, setData] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const fetchData = () => {
    api
      .get({
        path: path,
        service: "job",
      })
      .then((res: AxiosResponse) => {
        if (res.data.data)
          switch (key) {
            case "myTeam": {
              setData(res.data.data);
              break;
            }
            case "workMode": {
              if (
                Object.values(res.data.data).reduce((acc, value) => {
                  return acc && value === 0;
                }, true)
              ) {
                setIsError(true);
              }

              setData(() => ({
                ...pieChartPreData,
                series: [
                  {
                    ...pieChartPreData.series[0],
                    data: Object.entries(res.data.data).map(
                      ([key, value], index) => ({
                        value: value,
                        name: workModeMap[key].label,
                        color: analyticsColors[index],
                        itemStyle: {
                          normal: {
                            color: analyticsColors[index],
                          },
                        },
                      })
                    ),
                  },
                ],
              }));

              break;
            }
            case "gender": {
              if (
                Object.values(res.data.data).reduce((acc, value) => {
                  return acc && value === 0;
                }, true)
              ) {
                setIsError(true);
              }

              setData(() => ({
                ...pieChartPreData,
                series: [
                  {
                    ...pieChartPreData.series[0],
                    data: Object.entries(res.data.data).map(
                      ([key, value], index) => ({
                        value: value,
                        name: genderMap[key].label,
                        color: analyticsColors[index],
                        itemStyle: {
                          normal: {
                            color: analyticsColors[index],
                          },
                        },
                      })
                    ),
                  },
                ],
              }));

              break;
            }
            case "headCount": {
              if (!isNotEmptyArray(res.data.data)) {
                setIsError(true);
                break;
              }

              const keys = res.data.data.reduce(
                (
                  acc: string[],
                  curr: { departments: { [key: string]: number } }
                ) => {
                  const departmentKeys = Object.keys(curr.departments);
                  return [
                    ...acc,
                    ...departmentKeys.filter((key) => !acc.includes(key)),
                  ];
                },
                [] as string[]
              );

              setData({
                ...stackedBarChartPreData,
                xAxis: {
                  ...stackedBarChartPreData["xAxis"],
                  data: res.data.data.map((entry: { month: string }) =>
                    String(entry.month)
                  ),
                },
                series: keys.map((key: string, index: number) => ({
                  data: res.data.data.map(
                    (d: { departments: { [x: string]: any } }) => {
                      return {
                        value: d.departments[key] ?? 0,
                        name: key,
                      };
                    }
                  ),
                  type: "bar",
                  stack: "x",
                  name: key,
                  color: analyticsColors[index],
                  itemStyle: {
                    normal: {
                      color: analyticsColors[index],
                    },
                  },
                })),
              });

              break;
            }
            case "teamSplit": {
              if (!isNotEmptyArray(res.data.data)) {
                setIsError(true);
                break;
              }

              const keys = res.data.data.reduce(
                (
                  acc: string[],
                  curr: { positions: { [key: string]: number } }
                ) => {
                  const departmentKeys = Object.keys(curr.positions ?? {});
                  return [
                    ...acc,
                    ...departmentKeys.filter((key) => !acc.includes(key)),
                  ];
                },
                []
              );

              setData({
                ...stackedBarChartPreData,
                xAxis: {
                  ...stackedBarChartPreData["xAxis"],
                  data: res.data.data.map((entry: { position_level: string }) =>
                    String(entry.position_level)
                  ),
                },
                series:
                  keys?.map((key: string, index: number) => ({
                    data: res.data.data.map(
                      (d: { positions: { [x: string]: any } }) => {
                        return {
                          value: d.positions[key] ?? 0,
                        };
                      }
                    ),
                    type: "bar",
                    stack: "x",
                    color: analyticsColors[index],
                    name: key,
                    itemStyle: {
                      normal: {
                        color: analyticsColors[index],
                      },
                    },
                  })) ?? [],
              });

              break;
            }
            default:
              break;
          }
        else setIsError(true);
      })
      .catch((err) => {
        setIsError(true);
        message.error(getApiErrorMsg(err));
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    fetchData();
  }, []);

  return { data, isLoading, isError };
};

export { useAnalytics };
